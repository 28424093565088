import React, { useState } from "react";
import Layout from "../Layout";
import "./contactpage.scss";
import axios from "axios";
import spinner from "../../assets/spinner.gif";
import swal from "sweetalert2";
import "sweetalert2/src/sweetalert2.scss";

import ReCAPTCHA from "react-google-recaptcha";
import { keys } from "../../config";
import SEO from "../seo";
console.log(keys);

const initialState = {
  first_name: "",
  last_name: "",
  email: "",
  number: "",
  company: "",
  message: "",
};
const ContactPage = ({ location }) => {
  const [values, setValues] = useState(initialState);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isTokenVerified, setIsTokenVerified] = useState(false);

  function handleInputChange(e) {
    setValues({ ...values, [e.target.name]: e.target.value });
  }

  async function handleOnSubmit(e) {
    e.preventDefault();

    setIsSubmitting(true);
    try {
      const response = await axios.post(`${keys.api_url}/contact`, values);
      setIsSubmitting(false);
      swal.fire({
        title: "Form submitted successfully. Thank you!",
        icon: "success",
        confirmButtonColor: "#12244A",
      });
      setValues(initialState);
    } catch (e) {
      console.log(e);
      swal.fire({
        title: "Oops! some error occured. Please try again later.",
        icon: "error",
        confirmButtonColor: "#12244A",
      });
      setIsSubmitting(false);
    }
  }

  async function handleOnChangeCaptcha(value) {
    try {
      const res = await axios.post(`${keys.api_url}/verify-captcha`, {
        response_key: value,
      });
      if (res.data.success) setIsTokenVerified(true);
      else setIsTokenVerified(false);
    } catch (e) {
      console.log(e);
    }
  }

  return (
    <Layout>
      <SEO
        title="Contact Form Submission"
        description="Reach out to register as a volunteer, donate online or become a corporate partner. Become a part of the movememt today."
        keywords=""
        img={`https://${location.host}/link-preview.jpg`}
      />
      <div className="contact">
        <h1>Contact</h1>
        <div className="divider"></div>

        <form onSubmit={handleOnSubmit}>
          <div className="col-half">
            <label>First Name</label>
            <input
              name="first_name"
              value={values.first_name}
              onChange={handleInputChange}
              required
            ></input>
          </div>
          <div className="col-half">
            <label>Last Name</label>
            <input
              name="last_name"
              value={values.last_name}
              onChange={handleInputChange}
              required
            ></input>
          </div>
          <div className="col-half">
            <label>Email</label>
            <input
              name="email"
              type="email"
              value={values.email}
              onChange={handleInputChange}
              required
            ></input>
          </div>
          <div className="col-half">
            <label>Contact Number</label>
            <input
              name="number"
              value={values.number}
              onChange={handleInputChange}
              required
            ></input>
          </div>
          <label>Company Name</label>
          <input
            name="company"
            value={values.company}
            onChange={handleInputChange}
            required
          ></input>
          <label>Message</label>
          <textarea
            name="message"
            value={values.message}
            onChange={handleInputChange}
            required
          ></textarea>
          <div style={{ width: "100%", marginTop: "10px" }}>
            <ReCAPTCHA
              sitekey={keys.captcha_site_key}
              onChange={handleOnChangeCaptcha}
            />
          </div>
          <button disabled={isSubmitting || !isTokenVerified}>
            {!isSubmitting ? (
              "Submit"
            ) : (
              <span>
                Submitting
                <img width="30" height="30" src={spinner} alt="spinner"></img>
              </span>
            )}
          </button>
        </form>

        <div className="bottom-panel">
          <p>
            There are many miles to go before we achieve our goals.
            <br />
            When you sponsor us, you sponsor the lives of millions.
          </p>
          <a className="btn" href="/partner/">
            Partner for a Cause
          </a>
          <a
            className="btn"
            target="_blank"
            href="https://www.unitedwaymumbai.org/ngo-1317"
          >
            Donate Online
          </a>
        </div>
      </div>
    </Layout>
  );
};

export default ContactPage;
